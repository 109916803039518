import React, { FC, useEffect, useState } from "react";
import { StaticQuery, graphql } from "gatsby";
import styles from "../components/index.style";
import PrimaryLayout from "../components/layout/primary/primary";
import SEO from "../components/seo";
import { Box, Text } from "theme-ui";
import IndexModal from "../components/index-modal";
/* import AnnouncementBanner from "../components/color-divider/announcement-banner"; */
import TrendingProducts from "../components/trending-products/trending-products-home";
import useFreezeBodyScroll from "../hooks/useFreezeBodyScroll";
import { ImageBanner } from "components/image-banner/image-banner";
import { NewsSection } from "components/news-section/new-section";
import { ImageButton } from "components/image-button/image-button";
/* import { BackgroundSection } from "components/background-section/background-section"; */
/* import { ImageLinksSection } from "components/image-links/image-link"; */
import ContentContainer from "components/container/content-container";
import { RichText } from "prismic-reactjs";
import { linkResolver } from "../utils/linkResolver";
import CtaButton from "components/button/cta-button";

const indexPageStaticQuery = graphql`
  query {
    file(relativePath: { eq: "popup.jpeg" }) {
      childImageSharp {
        gatsbyImageData(width: 650, layout: CONSTRAINED, placeholder: NONE)
      }
    }
    allPrismicHomeminimal {
      edges {
        node {
          data {
            announcement_text {
              raw
            }
            banner_subtitle {
              raw
            }
            banner_image {
              alt
              fluid(maxWidth: 2000) {
                ...GatsbyImgixFluid_noBase64
              }
            }
            cg_summary_header {
              cg_summary {
                raw
              }
              link {
                uid
                url
                link_type
              }
              cta_text {
                raw
              }
            }
            trending_products_summary {
              raw
            }
            news_images {
              image {
                alt
                fluid(maxWidth: 1200) {
                  ...GatsbyImgixFluid_noBase64
                }
              }
              link_new_images {
                uid
                url
                link_type
              }
              title_news {
                raw
              }
              subtitle {
                raw
              }
              cta {
                raw
              }
            }
            images_buttons {
              description {
                raw
              }
              image_button {
                alt
                fluid(maxWidth: 1200) {
                  ...GatsbyImgixFluid_noBase64
                }
              }
              link {
                uid
                url
                link_type
              }
              cta_button {
                raw
              }
              position_text
              has_button
              color
              big_image
              title_image {
                raw
              }
              link_image {
                uid
                url
                link_type
              }
              title_link {
                raw
              }
            }
            background_images {
              image {
                alt
                fluid(maxWidth: 300) {
                  ...GatsbyImgixFluid_noBase64
                }
              }
              link {
                uid
                url
                link_type
              }
            }
            background_section {
              background {
                url
                alt
                fluid(maxWidth: 1200) {
                  ...GatsbyImgixFluid_noBase64
                }
              }
              background_color
              link {
                uid
                url
                link_type
              }
              content {
                raw
              }
              cta {
                raw
              }
            }
          }
        }
      }
    }
  }

  fragment ShopifyProducts on ShopifyProductConnection {
    nodes {
      id
      title
      tags
      updatedAt
      handle
      createdAt
      shopifyId
      options {
        id
        name
        values
      }
      variants {
        id
        title
        sku
        price
        selectedOptions {
          name
          value
        }
        image {
          id
          originalSrc
          gatsbyImageData(width: $productWidth, layout: CONSTRAINED)
        }
        shopifyId
        availableForSale
      }
      images {
        id
        originalSrc
        gatsbyImageData(width: $productWidth, layout: CONSTRAINED)
      }
    }
  }
`;

const SplitArray = (array: any, splits: number) => {
  const length = array.length;
  const newArray = [];
  for (let i = 0; i < length; i += splits) {
    newArray.push(array.slice(i, i + splits));
  }
  return newArray;
};

const IndexPage: FC = () => (
  <StaticQuery<GatsbyTypes.Query>
    query={`${indexPageStaticQuery}`}
    render={(data) => {
      const preview = data.allPrismicHomeminimal.edges[0].node.data;
      const meta = preview?.meta_description;
      const bannerImage = preview?.banner_image;
      const bannerSubtitle = preview?.banner_subtitle;
      // cg summary
      const cgSummary = preview?.cg_summary_header;
      const cgSummaryText = RichText.render(
        cgSummary?.[0]?.cg_summary?.raw,
        linkResolver
      );
      const cgSummaryLink = cgSummary?.[0]?.link;
      const cgSummaryCta = RichText.asText(cgSummary?.[0]?.cta_text?.raw);
      // news
      const imagesNews = preview?.news_images;
      const firstNewsThree = imagesNews ? imagesNews.slice(0, 3) : null;
      const secondNewsThree = imagesNews ? imagesNews.slice(3, 6) : null;
      // image buttons
      const imagesButtons = preview?.images_buttons;
      const firstImagesButtons = imagesButtons ? imagesButtons[0] : null;
      const secondImagesButtons = imagesButtons ? imagesButtons[1] : null;
      const restOfImagesButtons = imagesButtons ? imagesButtons.slice(2) : null;
      // announcement banner
      /* const text = preview?.announcement_text; */
      // modal
      const bg = data.file?.childImageSharp?.gatsbyImageData;
      // background section
      /* const backgroundSection = preview?.background_section; */
      // get first image from background section
      /* const firstBackgroundImage = backgroundSection?.[0];
      const secondBackgroundImage = backgroundSection?.[1]; */

      // trending summary
      const trendingSummary = preview?.trending_products_summary;
      const trendingSummaryText = RichText.render(
        trendingSummary?.raw,
        linkResolver
      );

      const [showModal, setShowModal] = useState(false);

      useEffect(() => {
        const subscribed = localStorage.getItem("seen");
        if (!subscribed) {
          setTimeout(() => setShowModal(true), 4000);
        }
      }, []);

      useFreezeBodyScroll(showModal);

      return (
        <PrimaryLayout
          fluid={true}
          homeLink="/"
          pathPrefix="/collection"
          showNoticeBar={false}
        >
          <SEO title="Home" description={meta} />
          {showModal && (
            <IndexModal open={showModal} setOpen={setShowModal} bg={bg} image />
          )}
          {/* <VideoBanner /> */}
          <ImageBanner text={bannerSubtitle} image={bannerImage} />
          {/* {text && <AnnouncementBanner text={text} />} */}
          <ContentContainer width="100%">
            {/* CG Summary */}
            <Box sx={styles.contentText}>
              <Box sx={styles.summaryHeader}>
                <Text as="p">{cgSummaryText}</Text>
                <CtaButton
                  ctaButtonLink={cgSummaryLink}
                  ctaText={cgSummaryCta}
                  isNormal
                  hasArrow
                />
              </Box>
            </Box>
            {/* First Background Section */}
            {/* <BackgroundSection color={firstBackgroundImage?.background_color}>
              <Box sx={styles.wrapperBackground}>
                {RichText.render(
                  firstBackgroundImage?.content?.raw,
                  linkResolver
                )}
              </Box>
            </BackgroundSection> */}
            {/* Image Link */}
            <Box sx={styles.wrapper}>
              {firstImagesButtons && (
                <ImageButton
                  text={firstImagesButtons?.description}
                  image={firstImagesButtons?.image_button}
                  link={firstImagesButtons?.link}
                  position={firstImagesButtons?.position_text}
                  cta={firstImagesButtons?.cta_button}
                  hasButton={firstImagesButtons?.has_button}
                  color={firstImagesButtons?.color}
                  bigImage={firstImagesButtons?.big_image}
                  title={firstImagesButtons?.title_image}
                  titleImage={firstImagesButtons?.title_link}
                  linkImage={firstImagesButtons?.link_image}
                />
              )}
            </Box>
            {/* New Section */}
            <NewsSection news={firstNewsThree} title="Latest News" />
            {/* End New Section */}
            {/* Image Link */}
            <Box sx={styles.wrapper}>
              {secondImagesButtons && (
                <ImageButton
                  text={secondImagesButtons?.description}
                  image={secondImagesButtons?.image_button}
                  link={secondImagesButtons?.link}
                  position={secondImagesButtons?.position_text}
                  cta={secondImagesButtons?.cta_button}
                  hasButton={secondImagesButtons?.has_button}
                  color={secondImagesButtons?.color}
                  bigImage={secondImagesButtons?.big_image}
                  title={secondImagesButtons?.title_image}
                  titleImage={firstImagesButtons?.title_link}
                  linkImage={firstImagesButtons?.link_image}
                />
              )}
            </Box>
            {/* End Image Link */}
            {/* Trending Products */}
            <Box sx={styles.wrapper}>
              <Box sx={styles.shopWrap}>
                <TrendingProducts
                  slider={true}
                  withLink={true}
                  title="The Market"
                  summary={trendingSummaryText}
                />
              </Box>
            </Box>
            {/* End Trending Products */}
            {/* First Background Section */}
            {/* <BackgroundSection color={secondBackgroundImage?.background_color}>
              <Box sx={styles.wrapperBackground}>
                {RichText.render(
                  secondBackgroundImage?.content?.raw,
                  linkResolver
                )}
                <CtaButton
                  ctaButtonLink={secondBackgroundImage?.link}
                  ctaText={RichText.asText(secondBackgroundImage?.cta?.raw)}
                  isNormal
                />
              </Box>
            </BackgroundSection> */}
            {/* End Background Section */}
            {/* Image Link */}
            <Box sx={styles.wrapper}>
              {restOfImagesButtons &&
                restOfImagesButtons.map((item, index) => (
                  <ImageButton
                    key={`image-button-${index}`}
                    text={item?.description}
                    image={item?.image_button}
                    link={item?.link}
                    position={item?.position_text}
                    cta={item?.cta_button}
                    hasButton={item?.has_button}
                    color={item?.color}
                    bigImage={item?.big_image}
                    title={item?.title_image}
                    titleImage={item?.title_link}
                    linkImage={item?.link_image}
                  />
                ))}
            </Box>
            {/* End Image Link */}
            {/* New Section */}
            <NewsSection news={secondNewsThree} hasBackground />
            {/* End New Section */}
          </ContentContainer>
        </PrimaryLayout>
      );
    }}
  />
);

export default IndexPage;
